export default {
  app_storage_prefix: "sarstuff_teamsite-demo_dev",
  api_server: "/api",
  central_server: "https://central.sarstuff.co.uk/api",
  bing_key: "AoMKVVkBRY3_8gv8UJmlIHXp8DCIGGzv9Yw_50Q4WIqC5ujgtVszQK-xLpB34hF2",
  auth: {
    clientId: "623deddd0bcc211f9b3f515b",
    loginServer: "https://central.sarstuff.co.uk"
  },
  logRocket: false
};
